import { 
    createRouter, 
    // createWebHistory,
    createWebHashHistory 
} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('../views/ProjectsView.vue')
    },
    {
        path: '/friends',
        name: 'friends',
        component: () => import('../views/FriendsView.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/UsersView.vue')
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('../views/LogsView.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/ProfileView.vue')
    },
    {
        path: '/projects/:id',
        name: 'project',
        component: () => import('../views/ProjectView.vue')
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/AuthView.vue')
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('../views/HelpView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: () => import('../views/NotFoundView.vue')
    }

]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { top: 0 };
    }
})

export default router
